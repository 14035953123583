import axios from "axios";

export const deferredTaxMapping = async ({
  companyId,
  yearId,
  isAddition,
  name,
  value
}) => {
  try {
    console.log({
      companyId,
      yearId,
      isAddition,
      name,
      value
    })
    return await axios.post("/api/DeferredTax", {
      companyId,
      yearId,
      isAddition,
      name,
      value
    });
  } catch (error) {
    throw error;
  }
};

export const deferredTaxViewData = async ({ companyId, year, isBringDeferredTaxFoward }) => {
  try {
    const { data } = await axios.get(
      `/api/DeferredTax?companyId=${companyId}&year=${year}&isBringDeferredTaxFoward=${isBringDeferredTaxFoward}`
    );
    return data;
  } catch (error) {
    throw error;
  }
};

/** TODO: Update endpoint url when available */
export const deferredTaxDelete = async id => {
  try {
    const { data } = await axios.delete(`/api/InvestmentAllowance/investment-allowance/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};
