import React from "react";
import { Button } from "primereact/button";
import { Controller, useForm } from "react-hook-form";
import utils from "../../utils";
import { deferredTaxMapping } from "../../apis/DeferredTax";
import InputController from "../controllers/InputController";
import { Checkbox } from "primereact/checkbox";


const DeferredTaxMapping = ({ tbData, onTrialBalance, trialBalanceRefresh, toast }) => {
  const { errors, handleSubmit, control } = useForm();

  const [loading, setLoading] = React.useState(false);
  const [init] = React.useState(true);
  const [isAddition, setIsAdittion] = React.useState(false);
  React.useEffect(() => {}, [init, tbData, trialBalanceRefresh]);
  const onSubmit = async data => {
    if (loading) return;
    
    setLoading(true);
    const { name, value } = data;

    try {
      const companyId = sessionStorage.getItem("cid");
      const yearId = sessionStorage.getItem("yid");
      const response = await deferredTaxMapping({
        companyId: companyId,
        yearId: yearId,
        isAddition: isAddition,
        name: name,
        value: value
      });

      alert('Saved Successfully')
    } catch (error) {
      utils.apiErrorHandling(error, toast);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form className="p-d-flex p-jc-between" onSubmit={handleSubmit(onSubmit)}>
        <InputController
          type="text"
          Controller={Controller}
          control={control}
          errors={errors}
          controllerName="name"
          label="Name"
          required
          errorMessage="Value is required"
          labelWidth={120}
          className="p-d-flex p-ai-center"
        />
        <InputController
          type="number"
          Controller={Controller}
          control={control}
          errors={errors}
          controllerName="value"
          label="Value"
          required
          errorMessage="Value is required"
          labelWidth={120}
          className="p-d-flex p-ai-center"
        />
        <div className="p-field-checkbox" style={{ marginTop: 20 }}>
          <Checkbox
            inputId="isFairValueGain"
            checked={isAddition}
            onChange={e => setIsAdittion(e.checked)}
          />
          <label htmlFor="isFairValueGain" className="p-checkbox-label">
            Is Addition ?
          </label>
        </div>
        <div>
          <p style={{ color: "transparent", marginTop: 0, marginBottom: 5 }}>Submit</p>
          <Button
            type="submit"
            label={!loading ? "Submit" : null}
            icon={loading ? "pi pi-spin pi-spinner" : null}
            style={{ width: 250 }}
          />
        </div>
      </form>
    </>
  );
};

export default DeferredTaxMapping;
