import React from "react";
import { Column } from "primereact/column";
import { useCompany } from "../../store/CompanyStore";
import utils from "../../utils";
import { incomeTaxDelete, incomeTaxViewData } from "../../apis/IncomeTax";
import ViewModeDataTable from "../common/ViewModeDataTable";
import ViewLoader from "../common/ViewLoader";
import { Dropdown } from "primereact/dropdown";

const IncomeTaxView = ({ year, toast, showITLevy, isBringLossFoward, profit, educationTax }) => {
  const isMounted = React.useRef(false);
  const [{ companyId }] = useCompany();
  const [loading, setLoading] = React.useState();
  const [error, setError] = React.useState();
  const [incomeTaxData, setIncomeTaxData] = React.useState();
  React.useEffect(() => {
    localStorage.removeItem("allowable_list");
    if (!companyId) return;

    isMounted.current = true;
    const fetchIncomeTaxViewData = async () => {
      try {
        setError(null);
        setLoading(true);
        let data = await incomeTaxViewData({
          companyId,
          year,
          isItLevyView: showITLevy,
          isBringLossFoward,
          profit: profit,
          educationTax: educationTax
        });
        let selected_dis = [];
        data.forEach(item => {
          if (item.canDis) {
            item.canDelete = false;
            selected_dis.push({
              label: item.description,
              value: item.description
            });
          }
          if (item.canDisTotal) {
            selected_dis.push({
              label: item.description,
              value: item.description
            });
          }
          return item;
        });
        //Commented it to display the disallowable
       // localStorage.setItem("allowable_list", JSON.stringify(selected_dis));
       // data = data?.filter(x => !x.canDis);

        if (isMounted.current) {
          setIncomeTaxData(
            data.map(item => ({
              ...item,
              description: (
                <div className="p-d-flex p-jc-between p-ai-center">
                  <p className="margin-0">
                    {item.canBolden ? <strong>{item.description}</strong> : item.description}
                  </p>
                  {item.canDelete && (
                    <i
                      className="pi pi-times-circle delete"
                      style={{ fontSize: 14, marginTop: 2 }}
                      onClick={async () => {
                        try {
                          setLoading(true);
                          const data = await incomeTaxDelete(item.id);
                          if (data) {
                            toast.show(
                              utils.toastCallback({
                                severity: "success",
                                detail: data
                              })
                            );
                            fetchIncomeTaxViewData();
                          }
                        } catch (error) {
                          utils.apiErrorHandling(error, toast);
                        } finally {
                          setLoading(false);
                        }
                      }}></i>
                  )}
                </div>
              ),
              columnOne: item.canBolden ? (
                <>
                  {item.canUnderlineUpColumn1 && <div className="top-linje"></div>}
                  <strong>
                    <u>{item.columnOne}</u>
                  </strong>
                  {item.canUnderlineDownColumn1 && <div className="bottom-linje"></div>}
                </>
              ) : (
                <>
                  {item.canUnderlineUpColumn1 && <div className="top-lineb"></div>}
                  {item.columnOne}
                  {item.canUnderlineDownColumn1 && <div className="bottom-linej"></div>}
                </>
              ),
              columnTwo: item.canBolden ? (
                <>
                  {item.canUnderlineUpColumn2 && <div className="top-linej"></div>}
                  <strong>
                    <u>{item.columnTwo}</u>
                  </strong>
                  {item.canUnderlineDownColumn2 && <div className="bottom-linej"></div>}
                </>
              ) : (
                <>
                  {item.canUnderlineUpColumn2 && <div className="top-linen"></div>}
                  {item.columnTwo}
                  {item.canUnderlineDownColumn2 && <div className="bottom-lineb"></div>}
                </>
              )
            }))
          );
        }
      } catch (error) {
        let errorString = utils.apiErrorHandling(error, toast);
        setError(errorString);
      } finally {
        if (isMounted.current) setLoading(false);
      }
    };
    fetchIncomeTaxViewData();

    return () => (isMounted.current = false);
  }, [companyId, isBringLossFoward, showITLevy, toast, year, educationTax, profit]);

  if (error) return <p style={{ color: "#f00" }}>{error}</p>;

  return (
    <>
      {/*<label style={{ color: "#2196F3" }}>Selected DisAllowable</label>
      <Dropdown
        style={{ width: 180, marginLeft: 20 }}
        placeholder="View Disallowable values"
        options={JSON.parse(localStorage.getItem('allowable_list'))}
  />*/}

      {incomeTaxData && (
        <ViewModeDataTable value={incomeTaxData}>
          <Column field="description" header="Description"></Column>
          <Column field="columnOne" header="₦"></Column>
          <Column field="columnTwo" header="₦"></Column>
        </ViewModeDataTable>
      )}
      {loading && <ViewLoader />}
    </>
  );
};

export default IncomeTaxView;
